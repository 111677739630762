
import {computed, defineComponent, ref, watch} from "vue";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonGrid,
  IonInput, IonItem,
  IonLabel, IonList,
  IonRow, IonTitle,
  isPlatform, loadingController, onIonViewWillLeave
} from "@ionic/vue";
import {useStore} from "vuex";
import {User} from "@/domain/model/user/user.model";
import {
  UpdateUserWithProfileData,
  UpdateUserWithProfileDataFromUser
} from "@/domain/command/user/user.command";
import {Material} from "@/domain/model/material/material.model";
import _ from "lodash";
import {useViewStateApi} from "@/domain/service/api/viewState.api";
import router from "@/app/router";
import {useUserApi} from "@/domain/service/api/use-user-api";

export default defineComponent({
  name: "UpdateUserProfileComponent",
  components: {
    IonCard,
    IonCardHeader,
    IonTitle,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonInput,
    IonList,
    IonButton,
  },
  props: {
    userId: null,
  },
  setup(prop: any) {
    const isTablet = computed<boolean>(() => !isPlatform('desktop'));
    const store = useStore();
    const user = computed<User>(() => store.getters.userById(prop.userId));
    const command = ref<UpdateUserWithProfileData>(UpdateUserWithProfileDataFromUser(user.value));
    const isSubmitting = ref<boolean>(false);
    const updateHasBeenDone = ref<boolean>(false);
    const submit = async () => {
      if (isSubmitting.value || !updateHasBeenDone.value)
        return;
      isSubmitting.value = true;
      const loading = await loadingController
          .create({
            message: 'Modificando datos',
            duration: 5000,
          });

      await loading.present();
      const newCommand: UpdateUserWithProfileData = _.cloneDeep(command.value);

      await useUserApi().updateUserProfile(newCommand);
      updateHasBeenDone.value = false;
      isSubmitting.value = false;
      await loading.dismiss();
    }


    watch(command.value, async () => {
      updateHasBeenDone.value = true;
    })
    onIonViewWillLeave(() => {
      updateHasBeenDone.value = false;
    })
    return {
      isTablet,
      user,
      command,
      submit,
    };
  }
})
