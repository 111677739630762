
import {computed, defineComponent, ref} from "vue";
import {IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, isPlatform} from "@ionic/vue";
import UpdateUserProfileComponent from "@/app/component/UserProfile/UpdateUserProfileComponent.vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import {User} from "@/domain/model/user/user.model";

export default defineComponent({
  name: "UserBonusDetailsUserPage",
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonContent,
    UpdateUserProfileComponent
  },
  setup() {
    const route = useRoute();
    const store  =useStore();
    const userId = ref(route.params.id);
    const isTablet = computed<boolean>(() => !isPlatform('desktop'));
    const user = computed<User>(() => store.getters.userById(userId.value));
    return {
      userId,
      user,
      isTablet,
    };
  }
})
