import {Shift, User} from "@/domain/model/user/user.model";

export interface LoginUser {
    username: string;
    password: string;
}

export interface CreateUser {
    id: string;

    username: string;
    email: string | undefined;
    password: string;
    charge: string;
    shift: Shift;
    workerNumber: number;
}

export interface UpdateUser {
    id: string;
    username: string;
    email: string | undefined;
    charge: string;
    shift: Shift;
    dailyBonusIncome: number;
}

export interface UpdateUserWithProfileData {
    id: string;
    name: string;
    surname: string;
    address: string;
    phone: string;
    dni: string;
}

export function UpdateUserWithProfileDataFromUser(user: User): UpdateUserWithProfileData {
    return {
        id: user.id,
        name: user.name ?? '',
        surname: user.surname ?? '' ,
        address: user.address ?? '',
        phone: user.phone ?? '',
        dni: user.dni ?? '',
    }
}

export function updateUserByEntity(entity: User): UpdateUser {
    return {
        id: entity.id,
        username: entity.username,
        email: entity.email,
        charge: entity.charge,
        shift: entity.shift,
        dailyBonusIncome: entity.dailyBonusIncome,
    }
}

